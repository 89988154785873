import gql from 'graphql-tag';

import { inlineCommentFragment } from './InlineCommentFragment.fragment';

export const CreateInlineCommentMutation = gql`
	mutation CreateInlineCommentMutation($input: CreateInlineCommentInput!, $pageId: ID!) {
		createInlineComment(input: $input) {
			...inlineCommentFragment
			createdAt {
				value
			}
			location {
				type
				... on InlineComment {
					inlineMarkerRef
					inlineText
					inlineResolveProperties {
						resolvedByDangling
						resolvedTime
						resolvedFriendlyDate
						resolvedUser
						resolved
					}
				}
				... on FooterComment {
					commentResolveProperties {
						resolved
						resolvedFriendlyDate
						resolvedTime
						resolvedUser
					}
				}
			}
			reactionsSummary(pageId: $pageId, contentType: "page", childType: "comment") {
				ari
				containerAri
				reactionsCount
				reactionsSummaryForEmoji {
					id
					emojiId
					count
					reacted
				}
			}
			replies {
				...inlineCommentFragment
				createdAt {
					value
				}
				reactionsSummary(pageId: $pageId, contentType: "page", childType: "comment") {
					ari
					containerAri
					reactionsCount
					reactionsSummaryForEmoji {
						id
						emojiId
						count
						reacted
					}
				}
			}
		}
	}

	${inlineCommentFragment}
`;
